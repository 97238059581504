import React from "react";
import "./HomeContent.scss";

function HomeContent(props) {
    return (
        <div id="HomeContent-root" className="container-fluid px-md-5 py-5">
            <div id="comp-title" className={`row mt-3 ml-5 ${props.hide_title ? "d-none" : ""}`}>
                <div className={`col mx-auto mt-1 ${props.center ? "text-center" : ""}`}>
                    <h2>
                        {/* <i class="fa fa-caret-right mr-3"></i> */}
                        {props.title}
                    </h2>
                    <div className={`border-bawah ${props.center ? "mx-auto" : ""} mb-5`}></div>
                </div>
            </div>
            <div className="row mx-0 home-content-children">
                <div className="col mx-auto">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default HomeContent;