import React from "react";
import "./Profile.scss";

import NavBar from 'components/NavBar/NavBar';
import Footer from 'components/Footer/Footer';
import FullPageContent from 'components/Content/FullPageContent';
import Chat from "components/Chat/Chat";
import { getProfil } from "services/View";

// import gedung_img from 'assets/images/gedung.jpg';
// import profil_img from 'assets/images/profil.jpeg';

import Tracker from '../../components/Tracker/Tracker';


class Profile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profilImg: ""
        }
    }

    componentWillMount() {
        document.title = 'Profil Dinas Pendidikan'
    }

    componentDidMount() {
        getProfil(res => {
            this.setState({
                profilImg: res
            })
        })
    }

    render() {
        return (
            <div>
                <Tracker />
                <Chat />
                <NavBar index={1} />
                <div id="Profile-page">
                    <FullPageContent
                        full={true}
                    >
                        <div className="container-fluid">
                            <img src={this.state.profilImg} />
                        </div>
                    </FullPageContent>
                </div>
                <Footer />
            </div>
        );
    }
}


export default Profile;