import React from "react";
import "./ArticleGridDisplay.scss";
import ArticleBox from "./subcomponents/ArticleBox";
// import ContentPaper from "components/Content/ContentPaper/ContentPaper";
import { VideoModal } from "components/VideoPopup/VideoPopup";


class ArticleGridDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            video_source: "https://www.youtube.com/embed/",
            title: null,
            date: null,
            type_id: 0,
            type_name: "",
            description: null
        };
        this.setVideoSource = this.setVideoSource.bind(this);
        this.clearVideoSource = this.clearVideoSource.bind(this);
    }

    setVideoSource(src, item) {
        this.setState(state => ({
            video_source: src,
            title: item.title,
            date: item.date,
            type_id: item.type_id,
            type_name: item.type_name,
            description: item.description
        }));
    }

    clearVideoSource() {
        this.setState(state => ({
            video_source: "https://www.youtube.com/embed/"
        }));
    }

    render() {
        var items = this.props.galleryItems;
        items = items.map((item, key) => {

            let video_source = `https://www.youtube.com/embed/${item.youtube_id}`;
            let thumb_source = `https://img.youtube.com/vi/${item.youtube_id}/hqdefault.jpg`;

            if (!item.isVideo) {
                thumb_source = item.mediaLink;
            }

            return (
                <div className="col-lg-4 col-md-6 col-sm-12 my-3 text-center">
                    <div onClick={() => this.setVideoSource(video_source, item)}>
                        <ArticleBox
                            img={thumb_source}
                            title={item.title}
                            date={item.date}
                            type_id={item.type_id}
                            type_name={item.type_name}
                            isVideo={item.isVideo}
                            mediaLink={item.mediaLink}
                            description={item.description}
                        >
                            {/* <div className="col text-left">
                                <button type="button" class="link-desc btn btn-link btn-sm btn-block mx-auto" data-toggle="modal" data-target="#descriptionModal">
                                    Baca Deskripsi
                                </button>
                            </div>
                            <div className="col text-right">
                                <a className="link-desc mx-auto" href={item.mediaLink} target="_blank">
                                    Buka Link
                                </a>
                            </div> */}
                            <div>
                                <a 
                                    style={{"font-size":"12px", "cursor":"pointer"}} 
                                    className="mt-2" 
                                    data-toggle="modal" 
                                    data-target="#descriptionModal"
                                >
                                    <i class='far fa-edit'></i>
                                </a>
                            </div>
                        </ArticleBox>
                    </div>
                </div>
            )
        });

        return (
            <div id="ArticleGridDisplay-root">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        {items}
                    </div>
                </div>
                <VideoModal onCloseCallback={this.clearVideoSource} video_source={this.state.video_source}>
                    {/* <ContentPaper
                        title={this.state.title}
                        date={this.state.date}
                        type_id={this.state.type_id}
                        thumbnail={null}
                    >
                        <div className="container-fluid">
                            {this.state.description}
                        </div>
                    </ContentPaper> */}
                </VideoModal>
                {/* deskripsi modal */}
                <div className="modal fade" id="descriptionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Deskripsi Galeri</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{ padding: "20px" }}>
                                {this.state.description}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ArticleGridDisplay;