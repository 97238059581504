import React from "react";
import "./LinkGridDisplay.scss";
import HomeContent from "components/Layout/HomeContent/HomeContent";
import LinkBox from "./subcomponents/LinkBox";
import { getStaticImage } from "utils/image";

import link1_img from 'assets/images/link/logobantuan-clean.png'; // INI GAK MUNCUL
import link2_img from 'assets/images/link/logosiswa-clean.png'; // INI GAK MUNCUL
import link3_img from 'assets/images/link/logosekolah-clean.png'; // INI GAK MUNCUL
import link4_img from 'assets/images/link/logo-kjp.jpg';
import link5_img from 'assets/images/link/logo-beasiswa_jakarta.png';
import link6_img from 'assets/images/link/logo-bse.png';
import link7_img from 'assets/images/link/logo-rubel.png';
import link8_img from 'assets/images/link/logo-ijakarta.png';
// var link1_img = getStaticImage('assets/images/link/logobantuan.png');
// var link2_img = getStaticImage('assets/images/link/logosiswa.png');
// var link3_img = getStaticImage('assets/images/link/logosekolah.png');
// var link4_img = getStaticImage('assets/images/link/logo-kjp.jpg');
// var link5_img = getStaticImage('assets/images/link/logo-beasiswa_jakarta.png');
// var link6_img = getStaticImage('assets/images/link/logo-bse.png');
// var link7_img = getStaticImage('assets/images/link/logo-rubel.png');
// var link8_img = getStaticImage('assets/images/link/logo-ijakarta.png');


function getLinks() {
    var link1 = {
        img: link1_img,
        text: "Portal BOS",
        href: "https://bos.kemdikbud.go.id/",
    }
    var link2 = {
        img: link2_img,
        text: "Nomor Induk Siswa Nasional",
        href: "https://nisn.data.kemdikbud.go.id/page/data",
    }
    var link3 = {
        img: link3_img,
        text: "Nomor Pokok Sekolah Nasional",
        href: "https://referensi.data.kemdikbud.go.id/index11.php?kode=010000&level=1",
    }
    var link4 = {
        img: link4_img,
        text: "Kartu Jakarta Pintar",
        href: "https://kjp.jakarta.go.id/kjp2/",
    }
    var link5 = {
        img: link5_img,
        text: "Beasiswa Jakarta",
        href: "http://beasiswajakarta.com/v2/",
    }
    var link6 = {
        img: link6_img,
        text: "Buku Sekolah Elektronik",
        href: "http://bse.kemdikbud.go.id/",
    }
    var link7 = {
        img: link7_img,
        text: "Rumah Belajar",
        href: "https://belajar.kemdikbud.go.id/",
    }
    var link8 = {
        img: link8_img,
        text: "Perpustakaan Digital Jakarta",
        href: "https://ijakarta.id/",
    }
    var links = [link5, link2, link3, link1, link4, link8, link7, link6]
    return links;
}

function LinkGridDisplay() {

    var links = getLinks();
    links = links.map((link, key) => {
        return (
            <LinkBox img={link.img} text={link.text} href={link.href} />
        )
    });

    var ii = 0
    var size = 3;
    var links_list = []
    while (size * ii < links.length) {
        links_list.push(links.slice(size * ii, size * (ii + 1)));
        ii += 1;
    }

    var links_carousel = links_list.map((links, key) => {
        var active = key == 0 ? "active" : "";
        return (
            <div className={`carousel-item ${active}`}>
                <div className="row justify-content-center">
                    {links}
                </div>
            </div>
        );
    });

    return (
        <div id="LinkGridDisplay-root">
            <HomeContent title="Data Pendidikan" center={true}>
                <div className="container-fluid mx-sm-1 my-3">
                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            {links_carousel}
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </HomeContent>
        </div>
    )
}

export default LinkGridDisplay;