import React from "react";
import "./Footer.scss";
import { getStaticImage } from "utils/image";

import disdik_logo from 'assets/images/disdik-logo-border-clean.png';
import dki_logo from 'assets/images/jakarta-logo.png';
import fb_logo from 'assets/images/facebook-logo.png';
import ig_logo from 'assets/images/instagram-logo.png';
import twitter_logo from 'assets/images/twitter-logo.png';
// var disdik_logo = getStaticImage('assets/images/disdik-logo-border.png');
// var fb_logo = getStaticImage('assets/images/facebook-logo.png');
// var ig_logo = getStaticImage('assets/images/instagram-logo.png');
// var twitter_logo = getStaticImage('assets/images/twitter-logo.png');


function Footer(props) {
    return (
        <div id="Footer-root" className="container-fluid pt-4">
            <div id="main-footer" className="row mx-auto pt-5 pb-4 px-2">
                <div id="logo-footer" className="col-md-2 d-none d-md-block px-4 py-3">
                    <div className="text-center">
                        <img className="img-fluid" src={disdik_logo} />
                    </div>
                </div>
                <div id="address-footer" className="mx-auto col-md-6 col-12 px-md-3 px-5 py-3">
                    <div>
                        <h2>Dinas Pendidikan</h2>
                        <h3>Pemerintah Provinsi DKI Jakarta</h3>
                        <p>
                            Jln. Jendral Gatot Subroto, Kav. 40-41,
                            <br />
                            Jakarta Selatan
                            <br />
                            Telp. (021) 5255385
                        </p>
                    </div>
                </div>
                <div id="link-footer" className="mx-auto col-md-4 col-12 px-md-0 py-md-0 px-5 py-3">
                    <div className="text-center">
                        <p>Ikuti Kami</p>
                        <a href="https://id-id.facebook.com/disdikdkijakarta"><img src={fb_logo} /></a>
                        <a href="https://www.instagram.com/disdikdki/?hl=en"><img src={ig_logo} /></a>
                        <a href="https://twitter.com/disdik_dki?lang=en"><img src={twitter_logo} /></a>
                    </div>
                </div>
            </div>
            <div id="bottom-footer" className="row"></div>
        </div>
    )
}

export default Footer;