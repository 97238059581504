import React from "react";
import "./ArticleSearch.scss";

import NavBar from 'components/NavBar/NavBar';
import Footer from 'components/Footer/Footer';
import SearchContent from 'pages/ArticleSearch/subcomponents/SearchContent/SearchContent';
import Chat from "components/Chat/Chat";

import Tracker from '../../components/Tracker/Tracker';


class ArticleSearch extends React.Component {

    componentWillMount() {
        document.title = 'Cari Artikel | Dinas Pendidikan'
    }

    render() {
        return (
            <div>
                <Tracker />
                <Chat />
                <NavBar />
                <SearchContent />
                <Footer />
            </div>
        );
    }
}


export default ArticleSearch;