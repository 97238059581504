import React from "react";
import "./RadioApp.scss";

import HomeContent from "components/Layout/HomeContent/HomeContent";
import radioapp_img from 'assets/images/radioapp.png';


class RadioApp extends React.Component {

    render() {
        return (
            <div id="RadioApp-root">
                <HomeContent title="Radio Dinas Pendidikan">
                    <div className="container-fluid">
                        <div className="row mx-3 my-4">
                            <div className="col-md-6 col-sm-12">
                                <p>
                                    Aplikasi Radio Disdik menyiarkan berita-berita serta informasi
                                    seputar pendidikan. Dapatkan aplikasi ini untuk perangkat
                                    android anda dengan mengunduh aplikasinya di Play Store.
                                    Anda juga dapat melihat video-video dari Liputan Radio di
                                    website Dinas Pendidikan atau menonton video-video secara live
                                    di Youtube Radio Disdik.
                                </p>
                                <div className="mt-5">
                                    <audio ref="audio_tag" src="https://radioplay.pusdatikomdik.id/live" controls autoPlay/>
                                </div>
                            </div>
                            <div className="col-md-6 d-none d-md-block text-center">
                                <img id="gambarApp" className="mx-auto" width="350px" src={radioapp_img} />
                            </div>
                        </div>
                    </div>
                </HomeContent>
            </div>
        );
    }

}

export default RadioApp;