import ApiClient from '../utils/ApiRequest';


export function getCarousel(callback) {
    ApiClient.request(`
    query getView {
        getView(key: "carousel")
    }
    `).then(vw => {
        const view = vw.getView;
        if (view) {
            callback(view);
        }
    });
}

export function getPengumuman(callback) {
    ApiClient.request(`
    query getView {
        getView(key: "pengumuman")
    }
    `).then(vw => {
        const view = vw.getView;
        if (view) {
            callback(view);
        }
    });
}

export function getNewsBox(callback) {
    ApiClient.request(`
    query getView {
        getView(key: "newsbox")
    }
    `).then(vw => {
        const view = vw.getView;
        if (view) {
            callback(view);
        }
    });
}

export function getGalleryBox(callback) {
    ApiClient.request(`
    query getView {
        getView(key: "gallerybox")
    }
    `).then(vw => {
        const view = vw.getView;
        if (view) {
            callback(view);
        }
    });
}

export function getProduk(callback) {
    ApiClient.request(`
    query getView {
        getView(key: "produk")
    }
    `).then(vw => {
        const view = vw.getView;
        if (view) {
            callback(view);
        }
    });
}

export function getProfil(callback) {
    ApiClient.request(`
    query getView {
        getView(key: "profile")
    }
    `).then(vw => {
        const view = vw.getView;
        if (view) {
            callback(view);
        }
    });
}

export function getRunningText(callback) {
    ApiClient.request(`
    query getView {
        getView(key: "runningtext")
    }
    `).then(vw => {
        const view = vw.getView;
        if (view) {
            callback(view);
        }
    });
}

export function getNavbar(callback) {
    ApiClient.request(`
    query getView {
        getView(key: "navbar")
    }
    `).then(vw => {
        const view = vw.getView;
        if (view) {
            callback(view);
        }
    });
}