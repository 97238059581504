import React from "react";
import "./TextImageSide.scss";

import { evalHTML } from "../../../../utils/HTML";
import HomeContentSide from 'components/Layout/HomeContentSide/HomeContentSide';
import { getProduk } from '../../../../services/View';


class TextImageSide extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            contents: []
        }
    }

    componentDidMount() {
        getProduk(contents => {
            this.setState({
                contents: JSON.parse(contents)
            })
        });
    }

    render() {

        if (this.state.contents.length == 0) {
            return null;
        }

        return (
            <div id="TextImageSide-root" className="container-fluid">
                <div id="Produk-Container">
                {
                    this.state.contents.map((item, idx) => {
                        return (
                            <div className="row">
                                <HomeContentSide title={item.title} image={item.image} first={idx % 2 == 0}>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <p className="text-justify">
                                                {evalHTML(item.description)}
                                            </p>
                                        </div>
                                    </div>
                                </HomeContentSide>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        )
    }
}


export default TextImageSide;


// import React from "react";
// import "./TextImageSide.scss";

// import HomeContentSide from 'components/Layout/HomeContentSide/HomeContentSide';

// import kjp_img from 'assets/images/produk/kjp.jpg';
// import radio_img from 'assets/images/produk/radio.jpeg';
// import ide_img from 'assets/images/produk/ide.jpeg';
// import ppdb_img from 'assets/images/produk/ppdb.jpeg';
// import ocs_img from 'assets/images/produk/ocs.jpeg';
// import kjmu_img from 'assets/images/produk/kjmu.jpg';


// function TextImageSide(props) {

//     return (
//         <div id="#TextImageSide-root" className="container-fluid">
//             <div className="row">
//                 <HomeContentSide title="Radio Dinas Pendidikan" image={radio_img} first={false}>
//                     <div className="container-fluid">
//                         <div className="row">
//                             <p className="text-justify">
//                                 Aplikasi Radio Disdik menyiarkan berita-berita serta informasi seputar pendidikan.
//                                 Dapatkan aplikasi ini untuk perangkat android anda dengan mengunduh aplikasinya
//                                 di <a href="https://play.google.com/store/apps/details?id=appinventor.ai_radiodisdik.RadioDIsdikDKI&hl=in">Play Store</a>.
//                                 Anda juga dapat melihat video-video dari Liputan Radio di website Dinas Pendidikan atau
//                                 menonton video-video secara live di  
//                                 <a href="https://www.youtube.com/channel/UCpJMrFXpylOMjZwPK5qGPvg"> Youtube </a> 
//                                 Radio Disdik.
//                             </p>
//                         </div>
//                     </div>
//                 </HomeContentSide>
//             </div>
//             <div className="row">
//                 <HomeContentSide title="Kartu Jakarta Pintar" image={kjp_img} first={true}>
//                     <div className="container-fluid">
//                         <div className="row">
//                             <p className="text-justify">
//                                 <a href="https://kjp.jakarta.go.id/kjp2/"> Kartu Jakarta Pintar Plus (KJP Plus) </a> 
//                                 adalah program strategis untuk memberikan akses bagi warga DKI Jakarta 
//                                 dari kalangan masyarakat kurang mampu untuk menyelesaikan pendidikan hingga 
//                                 jenjang SMA/SMK negeri dan swasta dengan biaya penuh dari dana 
//                                 APBD Provinsi DKI Jakarta.
//                             </p>
//                         </div>
//                     </div>
//                 </HomeContentSide>
//             </div>
//             <div className="row">
//                 <HomeContentSide title="Kartu Jakarta Mahasiswa Unggul" image={kjmu_img} first={false}>
//                     <div className="container-fluid">
//                         <div className="row">
//                             <p className="text-justify">                                 
//                                 <a href="https://kjp.jakarta.go.id/kjp2/"> Kartu Jakarta Mahasiswa Unggul (KJMU) </a> 
//                                 merupakan program lanjutan dari KJP 
//                                 Plus bagi Peserta Didik yang diterima di Perguruan Tinggi negeri di 
//                                 seluruh Indonesia untuk memberikan akses bagi warga DKI Jakarta dari 
//                                 kalangan masyarakat kurang mampu untuk menyelesaikan pendidikan hingga 
//                                 jenjang sarjana dengan biaya penuh dari dana APBD Provinsi DKI Jakarta. 
//                             </p>
//                         </div>
//                     </div>
//                 </HomeContentSide>
//             </div>
//             <div className="row">
//                 <HomeContentSide title="Informasi Data Elektronik" image={ide_img} first={true}>
//                     <div className="container-fluid">
//                         <div className="row">
//                             <p className="text-justify">
//                                 <a href="https://play.google.com/store/apps/details?id=com.disdik.ide">Aplikasi ID.E </a> 
//                                 khusus dan fokus pada Informasi seputar Data Dinas Pendidikan 
//                                 Provinsi DKI Jakarta. Aplikasi Data Pendidikan berbasis android ini merupakan 
//                                 suatu aplikasi yang berisi informasi data sekolah seperti alamat sekolah, 
//                                 data siswa, pendidik, dan tenaga kependidikan yang dapat diakses secara online 
//                                 dengan cepat, akurat, mudah dan terkini (<i>Up To Date</i>).
//                             </p>
//                         </div>
//                     </div>
//                 </HomeContentSide>
//             </div>
//             <div className="row">
//                 <HomeContentSide title="One Click Service" image={ocs_img} first={false}>
//                     <div className="container-fluid">
//                         <div className="row">
//                             <p className="text-justify">
//                                 <a href="http://103.134.18.34/ocs">One Click Service </a> 
//                                 merupakan Aplikasi yang dibangun Dinas Pendidikan Provinsi DKI Jakarta 
//                                 untuk memudahkan jenis-jenis layanan dinas pendidikan antara lain tambah PTK baru di 
//                                 Dapodik, Approve Mutasi PTK, Approve pemenuhan jam mengajar di sekolah non induk, 
//                                 Merubah status induk sekolah, serta Approve peserta didik status di Luar Dapodik dengan 
//                                 persyaratan dokumen yang diperlukan.
//                             </p>
//                         </div>
//                     </div>
//                 </HomeContentSide>
//             </div>
//             <div className="row">
//                 <HomeContentSide title="PPDB" image={ppdb_img} first={true}>
//                     <div className="container-fluid">
//                         <div className="row">
//                             <p className="text-justify">
//                                 Layanan SIAP PPDB Online 
//                                 adalah sebuah Sistem Layanan yang dirancang untuk 
//                                 memfasilitasi otomatisasi pelaksanaan Penerimaan Peserta Didik Baru (PPDB), 
//                                 mulai proses pendaftaran, seleksi hingga pengumuman hasil seleksi secara 
//                                 <i> realtime</i>. Layanan ini disediakan berbasis Web yang dapat membantu 
//                                 sekolah dalam melaksanakan Layanan PPDB dengan lebih mudah, cepat, 
//                                 transparan, akurat dan akuntabel secara <i>Online</i> diperuntukan bagi 
//                                 jenjang SD, SMP, SMA, dan SMK Negeri di Provinsi DKI Jakarta.
//                             </p>
//                         </div>
//                     </div>
//                 </HomeContentSide>
//             </div>
//         </div>
//     )
// }

// export default TextImageSide;