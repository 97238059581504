import React from "react";
import "./Imageshow.scss";
import disdik_logo from 'assets/images/disdik-logo-border-clean.png';
import kementrian_logo from 'assets/images/kementrian-logo.svg';
import jakarta_logo from 'assets/images/jakarta-logo.png';

function Imageshow() {
    return (
        <div className="logo align-items-center d-block h-100">
            <img className="img-logo" src={disdik_logo} alt="Logo" />
            <img className="img-logo" src={jakarta_logo} alt="Logo" />
            <img className="img-logo" src={kementrian_logo} alt="Logo" />
        </div>
    );
}

export default Imageshow;