import React from "react";
import "./Pengumuman.scss";
import { getPengumuman } from "../../../../services/View";
import { evalHTML } from "../../../../utils/HTML";


class Pengumuman extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pengumuman: null
        }
    }

    componentDidMount() {
        getPengumuman(pengumuman => {
            this.setState({
                pengumuman: JSON.parse(pengumuman)
            });
        });
    }

    render() {

        if (this.state.pengumuman == null || this.state.pengumuman.active == false) {
            return null;
        }

        return (
            <div id="Pengumuman-root" className="container-fluid px-1 px-md-5 py-5">
                <div className="px-3 px-md-5">
                    <div className="judul-pengumuman pt-4 pb-2">
                        <h1 className="text-center">{this.state.pengumuman.title}</h1>
                    </div>
                    <div className="isi-pengumuman pt-4 pb-3 px-sm-0 px-md-5">
                        { evalHTML(this.state.pengumuman.content) }
                    </div>
                </div>
            </div>
        )
    }
}


export default Pengumuman;