import React from "react";
import $ from "jquery";
import "./NewsBox.scss";
import Badge from "components/Badges/Badge";

import img_blank from 'assets/images/content/blank.jpeg';


class NewsBox extends React.Component {

    constructor(props) {
        super(props);

        var first_article = this.props.articles[0];
        first_article = first_article ? first_article : {};

        const first_id = first_article.id;
        const first_slug = first_article.slug;
        const first_img = first_article.img;
        const first_title = first_article.title;
        const first_date = first_article.date;
        const first_content = first_article.content;
        const first_type_id = first_article.type_id;
        const first_type_name = first_article.type_name;

        this.state = {
            id: first_id,
            slug: first_slug,
            img: first_img ? first_img : img_blank,
            title: first_title,
            date: first_date,
            content: first_content,
            type_id: first_type_id,
            type_name: first_type_name,
        }
    }

    handleClick(id, img, title, date, content, type_id, type_name, slug) {
        this.setState({
            id: id,
            img: img ? img : img_blank,
            title: title,
            date: date,
            content: content,
            type_id: type_id,
            type_name: type_name,
            slug: slug
        });
    }

    componentDidMount() {
        $(".articleHighlight").hover(
            function () {
                $(this).addClass('shadow').css('cursor', 'pointer');
            }, function () {
                $(this).removeClass('shadow');
            }
        );
    }

    render() {
        var articles = this.props.articles;
        var otherArticle = []

        for (var ii = 0; ii < 4; ii++) {

            const item_idx = articles[ii % articles.length];
            const item = item_idx ? item_idx : {};

            const img = item.img ? item.img : img_blank;

            const articleHighlightWidth = this.props.withImage ? "col-md-8 col-xs-12" : "col-12 pl-5";

            otherArticle.push((
                <a href="#ArticleNewsDisplay-root">
                    <div
                        onClick={() => this.handleClick(
                            item.id, item.img, item.title, item.date, item.content, item.type_id, item.type_name, item.slug
                        )}
                        className="row articleHighlight py-3">
                        {
                            this.props.withImage && (
                                <div className="col-md-4 col-4 d-none d-sm-block">
                                    <img className="thumbnail" src={img} />
                                </div>
                            )
                        }
                        <div className={`${articleHighlightWidth}`}>
                            <div className="row title pr-2 mb-3 mt-2">
                                <Badge
                                    type_id={item.type_id}
                                    type_name={item.type_name}
                                />
                            </div>
                            <div className="row title pr-2">
                                <a href={`#ArticleNewsDisplay-root`}>
                                    <h2 style={{ textTransform: "uppercase" }}>{item.title}</h2>
                                </a>
                            </div>
                            <div className="row timestamp">
                                <p>
                                    <i class="fa fa-calendar mr-2"></i>
                                    {item.date}
                                </p>
                            </div>
                        </div>
                    </div>
                </a>
            ));
        }

        return (
            <div id="NewsBox-root" className="container-fluid">
                <div className="col-12 py-1">
                    <div className="row">
                        <div id="fullArticle" className="col-lg-7 col-12 px-md-5 py-3">
                            <div className="row">
                                <img className="thumbnail" src={this.state.img} />
                            </div>
                            <div className="row title mt-4">
                                <a href={`/article/${this.state.slug}`}>
                                    <h2 style={{ textTransform: "uppercase" }}>{this.state.title}</h2>
                                </a>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-sm-8 px-1 text-left">
                                    <p>
                                        <i class="fa fa-calendar mr-3"></i>
                                        {this.state.date}
                                    </p>
                                </div>
                                <div className="col-12 col-sm-4 text-right">
                                    <Badge
                                        type_id={this.state.type_id}
                                        type_name={this.state.type_name}
                                    />
                                </div>
                            </div>
                            <div className="content row my-4">
                                {this.state.content}
                            </div>
                            <div className="row mt-3">
                                <button id="readmore" type="button" class="btn btn-secondary-outline btn-block">
                                    <a href={`/article/${this.state.slug}`}>read more ...</a>
                                </button>
                            </div>
                        </div>
                        <div id="otherArticle" className="col-lg-5 col-12">
                            {otherArticle}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewsBox;