import React from "react";
import "./FullPageContent.scss";
import Paper from "./ContentPaper/ContentPaper";


function Content(props) {

    if (props.full) {
        return (
            <div id="FullPageContent-root" className="container-fluid px-md-5 py-3 py-md-5">
                <div className="row mx-md-5 px-md-2 mt-2 mb-4">
                    <div id="full">
                        {props.children}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div id="FullPageContent-root" className="container-fluid px-md-5 py-3 py-md-5">
            <div className="row mx-md-5 px-md-2 mt-2 mb-4">
                <Paper id="paper" {...props} />
            </div>
        </div>
    )
}

export default Content;