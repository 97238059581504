import React from "react";
import "./Gallery.scss";

import NavBar from 'components/NavBar/NavBar';
import GallerySearchDisplay from "./components/GallerySearchDisplay/GallerySearchDisplay";
import Footer from 'components/Footer/Footer';
import Chat from "components/Chat/Chat";

import Tracker from '../../components/Tracker/Tracker';


class Gallery extends React.Component {

    componentWillMount() {
        document.title = 'Cari Galeri | Dinas Pendidikan'
    }

    render() {
        return (
            <div>
                <Tracker />
                <Chat />
                <NavBar />
                <div id="Gallery-page">
                    <GallerySearchDisplay />
                </div>
                <Footer />
            </div>
        );
    }
}


export default Gallery;