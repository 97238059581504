import React from "react";
import "./GalleryDisplay.scss";
import HomeContent from "components/Layout/HomeContent/HomeContent"
import { VideoLink, VideoModal } from "components/VideoPopup/VideoPopup";

import { getGallery } from "../../../../services/Gallery";
import { get_youtube_id } from "../../../../utils/Youtube";
import { formattedDate } from "../../../../utils/Date";
import { evalHTML } from "../../../../utils/HTML";


class GalleryDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            video_source: "https://www.youtube.com/embed/",
            galleries: [],
            found: false,
        };
        this.setVideoSource = this.setVideoSource.bind(this);
        this.clearVideoSource = this.clearVideoSource.bind(this);
        this.title = props.title;
        this.galleries_property = props.galleries_property;
        this.videoId = props.videoId;
    }

    setVideoSource(src) {
        this.setState(state => ({
            video_source: src
        }));
    }

    clearVideoSource() {
        this.setState(state => ({
            video_source: "https://www.youtube.com/embed/"
        }));
    }

    componentDidMount() {
        const thisComponent = this;
        getGallery(this.galleries_property.categoriesId, null, this.galleries_property.limit, 0, (galleries) => {
            var this_galleries = galleries.map(item => {
                return {
                    youtube_id: get_youtube_id(item.mediaLink),
                    title: item.title,
                    type_id: item.category.id,
                    date: formattedDate(item.publishedAt, 'd M y'),
                    description: evalHTML(item.description),
                    // accomodate image
                    contentTypeId: item.contentType.id,
                    mediaLink: item.mediaLink
                }
            });
            thisComponent.setState({
                galleries: this_galleries,
                found: true
            });
        });
    }

    render() {
        if (!this.state.found)
            return (<p className="text-center">Loading ...</p>)

        var gallery_items = this.state.galleries.map((item, key) => {

            let video_source = `https://www.youtube.com/embed/${item.youtube_id}`;
            let thumb_source = `https://img.youtube.com/vi/${item.youtube_id}/mqdefault.jpg`;

            return (
                <div className="col-lg-3 col-md-6 col-12 px-md-3 py-3">
                    <div className="gallery-thumb mx-auto">
                        <div>
                            {
                                item.contentTypeId == 2 ? (
                                    <img src={thumb_source} />
                                ) : (
                                        <img src={item.mediaLink} />
                                    )
                            }
                        </div>
                        <div className="gallery-thumb-inner">
                            <div className="gallery-title">
                                {
                                    item.contentTypeId == 2 ? (
                                        <span>
                                            <VideoLink id={this.videoId}>
                                                <div onClick={() => this.setVideoSource(video_source)}>
                                                    <h2 style={{ textTransform: "uppercase" }}>{item.title}</h2>
                                                </div>
                                            </VideoLink>
                                            <p>{item.date}</p>
                                        </span>
                                    ) : (
                                            <span>
                                                <a href={item.mediaLink}>
                                                    <h2 style={{ textTransform: "uppercase" }}>{item.title}</h2>
                                                </a>
                                                <p>{item.date}</p>
                                            </span>
                                        )
                                }
                            </div>
                            <div className="gallery-description">
                                <p>{item.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });

        return (
            <div id="GalleryDisplay-root">
                <HomeContent title={this.title}>
                    <div className="container-fluid mx-3 px-md-4 px-2 py-3">
                        <div className="row">
                            {gallery_items}
                        </div>
                    </div>
                </HomeContent>
                <VideoModal id={this.videoId} onCloseCallback={this.clearVideoSource} video_source={this.state.video_source}>
                    {null}
                </VideoModal>
            </div>
        )
    }
}

export default GalleryDisplay;