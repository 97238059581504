import React from "react";
import "./ContentPaper.scss";
import Badge from "components/Badges/Badge"


function Paper(props) {

    return (
        <div id="paper" className="col mx-auto">
            <div className="row">
                <div className="col-12 px-5 pb-3">
                    {props.thumbnail &&
                        <div id="thumbnail" className="row mb-3 mt-5 px-md-3">
                            <img src={props.thumbnail} />
                        </div>
                    }
                    <div id="title" className="row mb-4 mt-5 mx-sm-2">
                        <div className="container-fluid">
                            <div className="row">
                                <h1>
                                    {props.title}
                                </h1>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-8 col-sm-12 px-1 text-left">
                                    <p>
                                        {
                                            props.date && (
                                                <span>
                                                    <i class="fa fa-calendar mr-3"></i>
                                                    {props.date}
                                                </span>
                                            )
                                        }
                                    </p>
                                </div>
                                <div className="col-md-4 col-sm-12 text-right">
                                    <Badge
                                        type_id={props.type_id}
                                        type_name={props.type_name}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="content" className="row my-4 mx-md-2">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Paper;