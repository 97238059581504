import React from "react";
import "./HomeContentSide.scss";


function HomeContentSide(props) {

    var image_style = {
        backgroundImage: `url(${props.image})`
    };

    var order = null;

    if (props.first) {
        order = 'order-first';
    } else {
        order = 'order-last';
    }

    return (
        <div className="container-fluid HomeContentSide-root">
            <div className="row mx-3">
                <div id="text-col" className="col-md-7 col-12 px-3 px-md-4 py-2">
                    <div className="side-text-box">
                        <div id="comp-title" className="row mx-3 mt-2">
                            <div className="col-12 pt-1 pb-3 mx-1 mt-3 text-left">
                                <h2>
                                    {/* <i class="fa fa-caret-right mr-3"></i> */}
                                    {props.title}
                                </h2>
                                <div className={`border-bawah mb-2`}></div>
                            </div>
                        </div>
                        <div className="row ml-3 mr-2 mt-2 mb-4 pr-3">
                            <div>
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="image-col" className={`col-md-5 col-12 ${order}`}>
                    <div className="image-div" style={image_style}></div>
                </div>
            </div>
        </div>
    )
}

export default HomeContentSide;