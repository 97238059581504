import React from "react";
import "./Home.scss";

import NavBar from 'components/NavBar/NavBar';
import Masthead from 'pages/Home/components/Masthead/Masthead';
import NewMasthead from 'pages/Home/components/NewMasthead/NewMasthead';
// import ArticleTabDisplay from "pages/Home/components/ArticleTabDisplay/ArticleTabDisplay";
import ArticleNewsDisplay from "pages/Home/components/ArticleNewsDisplay/ArticleNewsDisplay";
import GalleryDisplay from 'pages/Home/components/GalleryDisplay/GalleryDisplay';
import GalleryDisplayList from 'pages/Home/components/GalleryDisplay/GalleryDisplayList';
import LinkGridDisplay from 'pages/Home/components/LinkGridDisplay/LinkGridDisplay';
import TextImageSide from 'pages/Home/components/TextImageSide/TextImageSide';
import Footer from 'components/Footer/Footer';
import Pengumuman from 'pages/Home/components/Pengumuman/Pengumuman';
import Chat from "components/Chat/Chat";
import RadioApp from "./components/RadioApp/RadioApp";

import Tracker from '../../components/Tracker/Tracker';


class Home extends React.Component {

    componentWillMount() {
        document.title = 'Website Dinas Pendidikan DKI Jakarta'
    }

    render() {
        return (
            <div className="home-root">
                <Tracker />
                <Chat />
                <NavBar index={0} />
                {/* <Masthead /> */}
                <NewMasthead />
                <Pengumuman />
                {/* Arahan Kepala Dinas Pendidikan */}
                {/* <GalleryDisplay title="Arahan Kepala Dinas" videoId="arahan" galleries_property={{
                    categoriesId: [16], // video arahan kepala dinas
                    limit: 4,
                }} /> */}
                {/* Artikel Terbaru */}
                <ArticleNewsDisplay />
                {/* Galeri */}
                <GalleryDisplayList />
                {/* Data Pendidikan */}
                <LinkGridDisplay />
                {/* Display Produk */}
                <TextImageSide />
                {/* Radio Disdik App */}
                <RadioApp />
                <Footer />
            </div>
        );
    }

}

export default Home;