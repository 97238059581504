import React from "react";
import queryString from "query-string";
import "./GallerySearchDisplay.scss";
import Pagination from "components/Pagination/Pagination";
import SearchBar from "components/SearchBar/SearchBar";
import ArticleGridDisplay from "./subcomponents/ArticleGridDisplay/ArticleGridDisplay"

import { getAllCategory } from "../../../../services/Category";
import { getGallery, getSize } from "../../../../services/Gallery";
import { get_youtube_id } from "../../../../utils/Youtube";
import { formattedDate } from "../../../../utils/Date";
import { evalHTML } from "../../../../utils/HTML";


class GallerySearchDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            query: null,
            selectedOptions: [],
            unselectedOptions: [],
            with_image: true,
            gallery: [],
            gallery_size: 0,
            gallery_found: false,
            categoriesDict: {},
            categories_found: false,
        };
        this.state = this.parseQueryString();
        this.handlePagination = this.handlePagination.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.itemsCountPerPage = 12;
    }

    parseQueryString() {
        var search = window.location.search;
        const parsed = queryString.parse(search, {
            arrayFormat: 'comma',
            parseNumbers: true
        });
        if (parsed) {
            var page = parsed.page;
            var query = query;
            var selectedOptions = [parsed.selectedOptions].flat();
            var unselectedOptions = [parsed.unselectedOptions].flat();
            var with_image = parsed.with_image;
            return {
                page: page,
                query: query,
                selectedOptions: selectedOptions,
                unselectedOptions: unselectedOptions,
                with_image: with_image,
                gallery: this.state.gallery,
                gallery_size: this.state.gallery_size,
                gallery_found: this.state.gallery_found,
                categoriesDict: this.state.categoriesDict,
                categories_found: this.state.categories_found,
            }
        } else {
            return this.state;
        }
    }

    setQueryString(page, query, selectedOptions, unselectedOptions, with_image) {
        const stringified = queryString.stringify({
            page: page,
            query: query,
            selectedOptions: selectedOptions,
            unselectedOptions: unselectedOptions,
            with_image: with_image,
        }, { arrayFormat: 'comma' });
        var newUrl = window.location.href.replace(
            window.location.search,
            `?${stringified}`
        );
        window.history.replaceState({}, "ArticleSearch", newUrl);
    }

    handlePagination(page_number) {
        this.setState({
            page: page_number
        });
        this.setQueryString(
            page_number,
            this.state.query,
            this.state.selectedOptions,
            this.state.unselectedOptions,
            this.state.with_image,
        );
        const thisComponent = this;
        const categoriesId = this.state.selectedOptions;
        const title = this.state.query;
        const offset = this.itemsCountPerPage * (page_number - 1);
        const limit = this.itemsCountPerPage;
        this.searchGallery(categoriesId, title, limit, offset, false, (galleries, size) => {
            thisComponent.setState({
                gallery: galleries,
                gallery_size: size,
                gallery_found: true,
            });
        });
    }

    handleFormSubmit(query, selectedOptions, unselectedOptions) {
        this.setState({
            page: 1,
            query: query,
            selectedOptions: selectedOptions,
            unselectedOptions: unselectedOptions,
        });
        this.setQueryString(
            1,
            query,
            selectedOptions,
            unselectedOptions,
            this.state.with_image,
        );
        const thisComponent = this;
        const categoriesId = selectedOptions;
        const title = query;
        const offset = 0;
        const limit = this.itemsCountPerPage;
        this.searchGallery(categoriesId, title, limit, offset, true, (galleries, size) => {
            thisComponent.setState({
                gallery: galleries,
                gallery_size: size,
                gallery_found: true,
            });
        });
    }

    searchGallery(categoriesId, title, limit, offset, getSize_bool, callback) {
        getGallery(categoriesId, title, limit, offset, (galleries) => {
            var this_galleries = galleries.map(item => {
                return {
                    youtube_id: get_youtube_id(item.mediaLink),
                    title: item.title,
                    type_id: item.category.id,
                    type_name: item.category.name,
                    date: formattedDate(item.publishedAt, 'd M y'),
                    description: evalHTML(item.description),
                    isVideo: item.contentType && item.contentType.id === 3 ? false : true,
                    mediaLink: item.mediaLink
                }
            });
            if (getSize_bool) {
                getSize(categoriesId, title, (size) => {
                    callback(this_galleries, size);
                })
            } else {
                callback(this_galleries, this.state.gallery_size);
            }
        });
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     return nextState.activePage !== this.state.activePage;
    // }

    componentDidMount() {
        const thisComponent = this;
        getAllCategory(categories => {
            categories = categories.reduce((all, cat) => {
                all[cat.id] = cat.name;
                return all;
            }, {});
            thisComponent.setState({
                categoriesDict: categories,
                categories_found: true,
            });
        });
        const categoriesId = this.state.selectedOptions;
        const title = this.state.query;
        const offset = this.itemsCountPerPage * (this.state.page - 1);
        const limit = this.itemsCountPerPage;
        this.searchGallery(categoriesId, title, limit, offset, true, (galleries, size) => {
            thisComponent.setState({
                gallery: galleries,
                gallery_size: size,
                gallery_found: true,
            });
        });
    }

    render() {
        const this_galleryItems = this.state.gallery;

        return (
            <div id="GallerySearchContent-root" className="container-fluid px-md-5 py-3 py-md-5">
                <div id="GallerySearchContent-sizing">
                    <div id="GallerySearchContent-searchbar" className="row mx-md-5 px-sm-3 mt-2 mb-4">
                        <div className="col-12">
                            <SearchBar
                                allOptions={this.state.categoriesDict}
                                selectedOptions={this.state.selectedOptions}
                                unselectedOptions={this.state.unselectedOptions}
                                onSubmit={this.handleFormSubmit}
                            />
                        </div>
                    </div>
                    <div id="GallerySearchContent-article" className="row mx-md-5 px-sm-3">
                        <div className="container-fluid">
                            {this.state.gallery_found ? (
                                <ArticleGridDisplay galleryItems={this_galleryItems} />
                            ) : (
                                    <p className="text-center" style={{ height: "100px" }}>Loading ...</p>
                                )}
                        </div>
                    </div>
                    <div id="GallerySearchContent-pagination" className="row mx-md-5 px-sm-3">
                        <div className="col-12 my-4">
                            <Pagination
                                activePage={this.state.page}
                                itemsCountPerPage={this.itemsCountPerPage}
                                totalItemsCount={this.state.gallery_size}
                                pageRangeDisplayed={5}
                                onChange={this.handlePagination}
                            />
                        </div>
                    </div>
                </div>
            </div >
        )
    }

}


export default GallerySearchDisplay;