import React from "react";
import "./Pagination.scss";


class Pagination extends React.Component {

    constructor(props) {
        super(props);
        // pagination href # (scroll ke atas)
        // jadi semua link yang ada paginationnya harus pake hash #
        // supaya sebelum click pagination dan setelah linknya sama
        // untuk push state history
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     return nextProps.activePage !== this.props.activePage;
    // }

    render() {
        var activePage = this.props.activePage;
        var itemsCountPerPage = this.props.itemsCountPerPage;
        var totalItemsCount = this.props.totalItemsCount;
        var pageRangeDisplayed = this.props.pageRangeDisplayed;

        var first_page = 1;
        var last_page = Math.round(0.49 + totalItemsCount / itemsCountPerPage);

        var supposed_first = activePage - (pageRangeDisplayed - 1) / 2;
        var supposed_last = supposed_first + pageRangeDisplayed - 1;

        var min_diff = Math.max(0, first_page - supposed_first);
        var max_page = Math.min(last_page, supposed_last + min_diff);
        var max_diff = Math.max(0, supposed_last - last_page);
        var min_page = Math.max(first_page, supposed_first - max_diff);

        var pages = []

        var prev_page = Math.max(1, activePage - 1)
        var next_page = Math.min(max_page, activePage + 1)

        pages.push((
            <li className="page-item">
                <a className="page-link" href="#" onClick={() => this.props.onChange(prev_page)}>
                    &lt;
                </a>
            </li>
        ));

        for (var idx = min_page; idx <= max_page; idx++) {
            var active = ((idx == activePage) ? 'active' : '');
            const page_number = idx;
            pages.push((
                <li className={`page-item ${active}`}>
                    <a className="page-link" href="#" onClick={() => this.props.onChange(page_number)}>
                        {idx}
                    </a>
                </li>
            ));
        }

        pages.push((
            <li className="page-item">
                <a className="page-link" href="#" onClick={() => this.props.onChange(next_page)}>
                    &gt;
                </a>
            </li>
        ));

        return (
            <div id="Pagination-root" className="container-fluid mt-3">
                <ul className="pagination justify-content-center">
                    {pages}
                </ul>
            </div>
        )
    }
}

export default Pagination;