import React from "react";
import "./VideoPopup.scss";


export function VideoModal(props) {
    var id = props.id ? props.id : "videoModal";
    return (
        <div id="VideoPopup-root" className="container-fluid">
            {/* https://codepen.io/JacobLett/pen/xqpEYE */}
            {/* <!-- Modal --> */}
            <div className="modal fade" id={`${id}`} onClick={() => props.onCloseCallback()} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            {/* <!-- 16:9 aspect ratio --> */}
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe className="embed-responsive-item" src={`${props.video_source}?autoplay=0&amp;modestbranding=1&amp;showinfo=0`} id="video" allowscriptaccess="always" allow="autoplay"></iframe>
                            </div>
                            <div id="modal-children" className="container-fluid mx-0">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export function VideoLink(props) {
    var id = props.id ? props.id : "videoModal";
    return (
        <a className="video-link"
            data-toggle="modal"
            data-target={`#${id}`}
        >
            {props.children}
        </a>
    )
}

