
var properties = [
    {
        id: 1,
        name: "Profil",
        class: "badge-secondary"
    },
    {
        id: 8,
        name: "Berita Pendidikan",
        class: "badge-success"
    },
    {
        id: 13,
        name: "Regulasi Nasional",
        class: "badge-warning"
    },
    {
        id: 14,
        name: "Regulasi Provinsi DKI",
        class: "badge-warning"
    },
    {
        id: 15,
        name: "Regulasi Dinas Pendidikan",
        class: "badge-warning"
    },
    {
        id: 16,
        name: "Arahan Kepala Dinas",
        class: "badge-danger",
    },
    {
        id: 17,
        name: "Liputan Radio",
        class: "badge-danger",
    },
    {
        id: 18,
        name: "Profil Sekolah",
        class: "badge-danger",
    },
    {
        id: 19,
        name: "Infografis",
        class: "badge-primary",
    },
    {
        id: 20,
        name: "Berita Dinas Pendidikan",
        class: "badge-success"
    },
    {
        id: 21,
        name: "Berita Sekolah dan Peserta Didik",
        class: "badge-success"
    },
    {
        id: 22,
        name: "Berita Pendidik dan Tenaga Kependidikan",
        class: "badge-success"
    },
    {
        id: 23,
        name: "Undangan",
        class: "badge-primary"
    },
    {
        id: 24,
        name: "Home Learning",
        class: "badge-success"
    },
    {
        id: 25,
        name: "Home Learning Video",
        class: "badge-danger",
    },
    {
        id: 31,
        name: "PPDB",
        class: "badge-primary",
    },
    {
        id: 32,
        name: "PPDB",
        class: "badge-primary",
    },
]

export function getBadgeProperty(type_id) {
    var type_prop = {
        id: 6,
        name: "Lainnya",
        class: "badge-secondary"
    };
    for (var ii = 0; ii < properties.length; ii++) {
        if (properties[ii].id == type_id) {
            type_prop = properties[ii];
        }
    }
    // yang penting class (warna badge)
    // name hanya default value
    return type_prop;
}