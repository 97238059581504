import React from "react";
import "./LinkBox.scss";

function LinkBox(props) {
    return (
        <div className="col-lg-3 col-md-4 col-sm-12 my-3 text-center linkbox">
            <a href={props.href} target="_blank">
                <div className="row justify-content-center">
                    <img className="imgThumb" src={props.img} />
                </div>
                <div className="row mt-4 justify-content-center">
                    <a>{props.text}</a>
                </div>
            </a>
        </div>
    )
}

export default LinkBox;