import React from "react";
import "./Badge.scss";
import { getBadgeProperty } from "../../data/badgeStyle";


function Badge(props) {

    var badge_prop = getBadgeProperty(props.type_id)

    return (
        <span className={`badge ${badge_prop.class}`}>
            {props.type_name ? props.type_name : badge_prop.name}
        </span>
    )
}

export default Badge;