import React from "react";
import "./Chat.scss";


class Chat extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            scriptId: "livechat",
            script: null
        }
    }

    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://www.mylivechat.com/chatinline.aspx?hccid=15418040";
        script.id = this.state.scriptId;
        script.async = true;
        this.setState({
            script: script
        });
    }

    render() {
        if (this.state.script) {
            var insertedDOM = document.querySelectorAll(`script[id='${this.state.scriptId}']`);
            if (insertedDOM.length == 0) {
                document.body.appendChild(this.state.script);
            }
        }
        return null;
    }
}


export default Chat;