import React from "react";
import ReactGA from "react-ga";


class Tracker extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        ReactGA.initialize('UA-157912369-1');
        ReactGA.pageview(window.location.pathname +  window.location.search); 
    }

    render() {
        return null;
    }
}


export default Tracker;