import ApiClient from '../utils/ApiRequest';


function getFullArticle(id, callback) {
    var addArticleHits = () => {
        ApiClient.request(`
        mutation hit {    
            addArticleHits(id: ${id})
        }
        `).then(hits => {
            console.log(hits);
        });
    }
    ApiClient.request(`
    {
        getArticles
        (
            find: {
                articlesId: [${id}]
                reviewStatus: ACCEPT
            }
            limit: 1
            offset: 0
        )
        {
            id
            category {
                id
                name
            }
            title
            slug
            thumbnail
            content
            publishedAt
            createdBy {
                name
                workplace
            }
            hits
        }
    }
    `).then(art => {
        const article = art.getArticles[0];
        if (article) {
            addArticleHits();
            callback(article);
        }
    });
}


function getArticleDescription(categoriesId, title, limit, offset, callback) {
    // parameter
    var catidFind = categoriesId && categoriesId.length > 0 ? `categoriesId: [${categoriesId.map(id => id.toString()).join()}]` : "";
    var titleFind = title ? `title: $title` : "";
    var limit = limit ? limit : 5;
    var offset = offset ? offset : 0;
    // variable
    var variable = {
        title: title
    }
    var variable_query = title ? "($title: String)" : "";
    // query
    ApiClient.request(`
    query articles ${variable_query} {
        getArticles
        (
          find: {
            ${catidFind}
            ${titleFind}
            reviewStatus: ACCEPT
          }
          limit: ${limit.toString()}
          offset: ${offset.toString()}
        )
        {
          id
          category {
            id
            name
          }
          title
          slug
          thumbnail
          description
          createdAt
          publishedAt
          hits
        }
    }
    `, variable).then(art => {
        const article = art.getArticles;
        if (article) {
            callback(article);
        }
    });
}

function getSize(categoriesId, title, callback) {
    // parameter
    var catidFind = categoriesId && categoriesId.length > 0 ? `categoriesId: [${categoriesId.map(id => id.toString()).join()}]` : "";
    var titleFind = title ? `title: $title` : "";
    // variable
    var variable = {
        title: title
    }
    var variable_query = title ? "($title: String)" : "";
    // query
    ApiClient.request(`
    query articles ${variable_query} {
        getArticles
        (
          find: {
            ${catidFind}
            ${titleFind}
            reviewStatus: ACCEPT
          }
        )
        {
          id
        }
    }
    `, variable).then(art => {
        const article = art.getArticles;
        if (article) {
            callback(article.length);
        }
    });
}

function getNewestArticle(categoriesId) {
    return new Promise((resolve, reject) => {
        getArticleDescription(categoriesId, null, 4, 0, resolve);
    });
}

export {
    getFullArticle, getArticleDescription, getSize, getNewestArticle
}