import React from "react";
import GalleryDisplay from "./GalleryDisplay";
import { getGalleryBox } from "services/View";


class GalleryDisplayList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            found: false,
            galleries: [],
        }
    }

    componentDidMount() {
        getGalleryBox(result => {
            this.setState({
                galleries: JSON.parse(result),
                found: true,
            })
        });
    }

    render() {
        if (!this.state.found)
            return (<p className="text-center">Loading ...</p>)

        return (
            <div>
                {
                    this.state.galleries.map((gallery, index) => {
                        var style_n = index == 0 ? null : { marginTop: "-50px" };
                        return (
                            <div style={style_n}>
                                <GalleryDisplay
                                    title={gallery.name}
                                    videoId={`galeri-${index}`}
                                    galleries_property={{
                                        categoriesId: gallery.categoriesId,
                                        limit: 4,
                                        modal: true
                                    }}
                                />
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

export default GalleryDisplayList;