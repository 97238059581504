import React from "react";
import "./LaporCard.scss";

import { Link } from 'react-router-dom';


class LaporCard extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="col-lg-3 col-md-4 col-sm-6 d-flex align-items-stretch">
                <div className="Lapor-Card card" style={{ maxWidth: "20rem", marginTop: "20px", marginBottom: "20px" }}>
                    <img class="card-img-top" src={this.props.img} alt="Card image cap" />
                    <div class="card-body">
                        <h5 class="card-title">{this.props.title}</h5>
                        <p class="card-text">{this.props.description}</p>
                    </div>
                    <div class="card-footer" style={{backgroundColor: "#FFF"}}>
                        <Link
                            to={{
                                pathname: "/lapor/form/",
                                state: { iframe_link: this.props.link }
                            }}
                        >
                            Lapor Disini
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default LaporCard;