import React from "react";
import "./NavBar.scss";
// import brand from 'assets/images/disdik-logo.png';
// import { getLinks } from "../../data/navbar-item.js";
import { getRunningText, getNavbar } from "services/View.js";
import { evalHTML } from "utils/HTML";
import { Link } from 'react-router-dom';
import { getStaticImage } from "utils/image";

import brand from 'assets/images/disdiklogo-clean.png';
// var brand = getStaticImage('assets/images/disdiklogo.png');


class NavBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      runningtext: {
        active: false
      },
      navLink: [], //getLinks()
      foundNav: false,
      foundRT: false
    }
  }

  componentDidMount() {
    getRunningText(res => {
      this.setState({
        runningtext: JSON.parse(res),
        foundRT: true,
      })
    });
    getNavbar(res => {
      this.setState({
        navLink: JSON.parse(res),
        foundNav: true,
      })
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.foundNav && this.state.foundRT) {
      return false;
    }
    return true;
  }

  render() {
    var props = this.props;

    // links
    var links = this.state.navLink;

    // active page in navbar
    var active_index = props.index;
    active_index = Math.max(active_index, 0);
    active_index = Math.min(active_index, links.length - 1);

    // cretae navigation item without dropdown
    function createNavLink(title, href, active) {
      var activeClass = active ? "active" : "";
      return (
        <li className={`nav-item ${activeClass}`}>
          {
            href.startsWith("http") ? (
              <a className="nav-link" href={href}>{title}</a>
            ) : (
                <Link className="nav-link" to={href}>{title}</Link>
              )
          }
        </li>
      )
    }

    // create navigation item with dropdown
    function createNavDropdown(title, active, dropdowns) {
      var activeClass = active ? "active" : "";
      dropdowns = dropdowns.map((item, key) => {
        if (item == null) {
          return <div className="dropdown-divider"></div>
        }
        return item.href.startsWith("http") ? (
          <a className="dropdown-item" href={item.href}>{item.title}</a>
        ) : (
            <Link className="dropdown-item" to={item.href}>{item.title}</Link>
          )
      });
      return (
        <li className={`nav-item dropdown ${activeClass}`}>
          <a className="nav-link dropdown-toggle" href={null} id="navbardrop" data-toggle="dropdown">
            {title}
          </a>
          <div className="dropdown-menu">
            {dropdowns}
          </div>
        </li>
      )
    }

    // navItems
    var navItems = links.map((link, idx) => {
      if (link.dropdowns) {
        return createNavDropdown(link.title, idx == active_index, link.dropdowns);
      } else {
        return createNavLink(link.title, link.href, idx == active_index);
      }
    });

    var runningTextClass = this.state.runningtext.active ? "running" : "";

    return (
      <div>
        {
          this.state.runningtext.active && (
            <div id="runningtext">
              <marquee>
                {evalHTML(this.state.runningtext.content)}
              </marquee>
            </div>
          )
        }
        <nav className={`navbar navbar-light navbar-expand-lg fixed-top ${runningTextClass}`}>

          <a className="navbar-brand" href={null}>
            <img src={brand} />
          </a>

          {/* Toggler/collapsibe Button */}
          <button className="navbar-toggler mx-2" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navbar links */}
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav ml-auto mr-5">
              {navItems}
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default NavBar;