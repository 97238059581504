import React from "react";
import "./ArticlePage.scss";

import NavBar from 'components/NavBar/NavBar';
import Footer from 'components/Footer/Footer';
import FullPageContent from 'components/Content/FullPageContent';
import { getFullArticle } from '../../services/Article';
import { formattedDate } from '../../utils/Date';
import { evalHTML } from '../../utils/HTML';
import Chat from "components/Chat/Chat";

import Tracker from '../../components/Tracker/Tracker';


class ArticlePage extends React.Component {

    constructor(props) {
        super(props);
        const { match: { params } } = props;
        this.slug = params.slug;
        this.id = parseInt(this.slug.split("-")[0]);
        this.state = {
            article: {
                category: {}
            },
            found: false,
            script: null
        }
    }

    componentWillMount() {
        var title = this.slug.split("-")
            .slice(1)
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        document.title = `${title} | Dinas Pendidikan`
    }

    componentDidMount() {
        getFullArticle(this.id, article => {
            this.setState({
                article: article,
                found: true
            });

        });

        const script = document.createElement("script");
        script.src = "/isso/js/embed.min.js";
        script.async = true;
        var attr = document.createAttribute("data-isso");
        attr.value = "/isso/";
        script.setAttributeNode(attr);
        var lang = document.createAttribute("data-isso-lang");
        lang.value = "en";
        script.setAttributeNode(lang);
        var auhtor = document.createAttribute("data-isso-require-author");
        auhtor.value = "true";
        script.setAttributeNode(auhtor);
        var email = document.createAttribute("data-isso-require-email");
        email.value = "true";
        script.setAttributeNode(email);
        var css = document.createAttribute("data-isso-css");
        css.value = "true";
        script.setAttributeNode(css);

        this.setState({
            script: script
        });
    }

    render() {

        if (this.state.found == false) {
            return null;
        }

        document.body.appendChild(this.state.script);

        return (
            <div id="ArticlePage-root">
                <Tracker />
                <Chat />
                <NavBar />
                {/* <div className="w-100 thumbnail-container">
                    <img id="thumbnail" src={this.state.article.thumbnail} />
                </div> */}
                <FullPageContent
                    title={this.state.article.title}
                    date={formattedDate(this.state.article.publishedAt, 'd M y')}
                    type_id={this.state.article.category.id}
                    type_name={this.state.article.category.name}
                    thumbnail={this.state.article.thumbnail}
                >
                    {this.state.found ? (
                        evalHTML(this.state.article.content)
                    ) : (
                            <p className="text-center" style={{ height: "100px" }}>Loading ...</p>
                        )}
                    <div className="container">
                        <div className="row">
                            <p className="mt-5">
                                <strong className="mr-2"> Dibuat oleh: </strong><br />
                                {`${this.state.article.createdBy.name} - ${this.state.article.createdBy.workplace}`}
                            </p>
                        </div>
                        <div className="row">
                            <p className="mt-1">
                                Telah dilihat <strong> {this.state.article.hits} </strong> kali
                            </p>
                        </div>
                    </div>
                </FullPageContent>
                <section
                    id="isso-thread"
                    className="mx-auto"
                    data-title={this.state.article.title}
                    data-isso-id={`article-${this.id}`}
                ></section>
                <Footer />
            </div>
        );
    }
}

export default ArticlePage;