import React from "react";
import DOMPurify from 'dompurify';


export function evalHTML(html_string) {
    // di sanitize di backend aja (gak bisa pake iframe si dompurify)
    return (
        <div className="container-fluid" dangerouslySetInnerHTML={{ __html: html_string }}></div>
    )
    // return (
    //     <div className="container-fluid" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html_string) }}></div>
    // );
} 