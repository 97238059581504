import React from "react";
import "./NewMasthead.scss";

import { getCarousel } from "../../../../services/View";


class NewMasthead extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            carousel: []
        };
    }

    componentDidMount() {
        getCarousel(links => {
            this.setState({
                carousel: JSON.parse(links)
            });
        });
    }

    render() {

        return (
            <div id="NewMasthead-root" className="container-fluid">
                <div className="row">
                    <div className="carousel-container mx-auto">

                        <div id="demox" class="carousel slide" data-ride="carousel">


                            <ul class="carousel-indicators">
                                {
                                    this.state.carousel.map((link, idx) => {
                                        return (
                                            <li data-target="#demox" data-slide-to={`${idx}`} className={`${idx == 0 ? 'active' : ''}`}></li>
                                        )
                                    })
                                }
                            </ul>


                            <div class="carousel-inner">
                                {
                                    this.state.carousel.map((link, idx) => {
                                        return (
                                            <div className={`carousel-item ${idx == 0 ? 'active' : ''}`}>
                                                <div className="carousel-img">
                                                    <img class="imgCar" src={link.link} />
                                                </div>
                                                <div className="carousel-caption d-none d-md-block">
                                                    <h2 className="display-4">
                                                        {link.title}
                                                    </h2>
                                                    <p className="lead">
                                                        <a href={link.href} target="_blank" >
                                                            <span className="abu">{link.description}</span>
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                            // <div className={`carousel-item ${idx == 0 ? 'active' : ''}`} style={{ "backgroundImage": `url('${link.link}')` }}>
                                            //     <div className="carousel-caption d-none d-md-block">
                                            //         <h2 className="display-4">
                                            //             {link.title}
                                            //         </h2>
                                            //         <p className="lead">
                                            //             <a href={link.href} target="_blank" >
                                            //                 <span className="abu">{link.description}</span>
                                            //             </a>
                                            //         </p>
                                            //     </div>
                                            // </div>
                                        )
                                    })
                                }
                            </div>


                            <a class="carousel-control-prev" href="#demox" data-slide="prev">
                                <span class="carousel-control-prev-icon"></span>
                            </a>
                            <a class="carousel-control-next" href="#demox" data-slide="next">
                                <span class="carousel-control-next-icon"></span>
                            </a>

                        </div>

                    </div>
                </div>
            </div>
        );
    }
}


export default NewMasthead;