import ApiClient from '../utils/ApiRequest';


function getAllCategory(callback) {
    ApiClient.request(`
    {
        getCategories {
            id
            name
            child {
                id
                name
            }
        } 
    }
    `).then(cat => {
        var categories = cat.getCategories;
        categories = categories.reduce((all, cat) => {
            return [
                ...all,
                ...[{
                    id: cat.id,
                    name: cat.name
                }],
                ...cat.child.map(cat => {
                    return {
                        id: cat.id,
                        name: cat.name
                    }
                })
            ]
        }, []);
        if (categories) {
            callback(categories);
        }
    });
}


export { getAllCategory }