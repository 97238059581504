import { GraphQLClient } from 'graphql-request';


const client = new GraphQLClient('/api/', {
    // headers: {
    //     Authorization: 'Bearer my-jwt-token',
    // },
})


export default client;

