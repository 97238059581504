import React from "react";
import queryString from "query-string";
import "./SearchContent.scss";
import Badge from "components/Badges/Badge";
import Pagination from "components/Pagination/Pagination";
import SearchBar from "components/SearchBar/SearchBar";
import { Link } from "react-router-dom";

import { getAllCategory } from "../../../../services/Category";
import { getArticleDescription, getSize } from "../../../../services/Article";
import { formattedDate } from "../../../../utils/Date";
import { evalHTML } from '../../../../utils/HTML';

import img_blank from 'assets/images/content/blank.jpeg';


/**
 * Show results of searching content
 * 
 * @param {Object} props property of search result
 * @param {Object[]} props.articles searched articles
 * @param {string} props.articles.img image link
 * @param {string} props.articles.title article title
 * @param {string} props.articles.date article date
 * @param {number} props.articles.type_id article type (badge)
 * @param {string} props.articles.description article description
 * @param {boolean} props.with_image flag of whether using image in search result
 */
class SearchContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            query: null,
            selectedOptions: [],
            unselectedOptions: [],
            with_image: true,
            articles: [],
            articles_size: 0,
            articles_found: false,
            categoriesDict: {},
            categories_found: false,
        };
        this.state = this.parseQueryString();
        this.handlePagination = this.handlePagination.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.initializeState = this.initializeState.bind(this);
        this.itemsCountPerPage = 10;
        this.firstRender = true;
    }

    parseQueryString() {
        var search = window.location.search;
        // window_location saat ini (track pergantian location dari luar)
        this.window_location = window.location.search;
        const parsed = queryString.parse(search, {
            arrayFormat: 'comma',
            parseNumbers: true
        });
        if (parsed) {
            var page = parsed.page;
            var query = query;
            var selectedOptions = [parsed.selectedOptions].flat();
            var unselectedOptions = [parsed.unselectedOptions].flat();
            var with_image = parsed.with_image == "true";
            return {
                page: page ? page : 1,
                query: query,
                selectedOptions: selectedOptions,
                unselectedOptions: unselectedOptions,
                with_image: with_image,
                articles: this.state.articles,
                articles_size: this.state.articles_size,
                articles_found: this.state.articles_found,
                categoriesDict: this.state.categoriesDict,
                categories_found: this.state.categories_found,
            }
        } else {
            return this.state;
        }
    }

    setQueryString(page, query, selectedOptions, unselectedOptions, with_image) {
        const stringified = queryString.stringify({
            page: page,
            query: query,
            selectedOptions: selectedOptions,
            unselectedOptions: unselectedOptions,
            with_image: with_image,
        }, { arrayFormat: 'comma' });
        var newUrl = window.location.href.replace(
            window.location.search,
            `?${stringified}`
        );
        window.history.replaceState({}, "ArticleSearch", newUrl);
        // window_location saat ini (track pergantian location dari luar)
        this.window_location = `?${stringified}`;
    }

    handlePagination(page_number) {
        this.setState(prevState => ({
            page: page_number,
            // slow image load
            articles: prevState.articles.map(item => {
                item.img = null;
                return item;
            }),
        }));
        this.setQueryString(
            page_number,
            this.state.query,
            this.state.selectedOptions,
            this.state.unselectedOptions,
            this.state.with_image,
        );
        const thisComponent = this;
        const categoriesId = this.state.selectedOptions;
        const title = this.state.query;
        const offset = this.itemsCountPerPage * (page_number - 1);
        const limit = this.itemsCountPerPage;
        this.searchArticle(categoriesId, title, limit, offset, false, (articles, size) => {
            thisComponent.setState({
                articles: articles,
                articles_size: size,
                articles_found: true,
            });
        });
    }

    handleFormSubmit(query, selectedOptions, unselectedOptions) {
        this.setState({
            page: 1,
            query: query,
            selectedOptions: selectedOptions,
            unselectedOptions: unselectedOptions,
        });
        this.setQueryString(
            1,
            query,
            selectedOptions,
            unselectedOptions,
            this.state.with_image,
        );
        const thisComponent = this;
        const categoriesId = selectedOptions;
        const title = query;
        const offset = 0;
        const limit = this.itemsCountPerPage;
        this.searchArticle(categoriesId, title, limit, offset, true, (articles, size) => {
            thisComponent.setState({
                articles: articles,
                articles_size: size,
                articles_found: true,
            });
        });
    }

    searchArticle(categoriesId, title, limit, offset, getSize_bool, callback) {
        getArticleDescription(categoriesId, title, limit, offset, (articles) => {
            var this_articles = articles.map(item => {
                var content = null;
                // content = `Telah dibuka <strong>${item.hits}</strong> kali<br>`;
                content = item.description;
                content = evalHTML(content);
                return {
                    id: item.id,
                    img: item.thumbnail,
                    title: item.title,
                    slug: item.slug,
                    date: formattedDate(item.publishedAt, 'd M y'),
                    content: content,
                    type_id: item.category.id,
                    type_name: item.category.name,
                }
            });
            if (getSize_bool) {
                getSize(categoriesId, title, (size) => {
                    callback(this_articles, size);
                })
            } else {
                callback(this_articles, this.state.articles_size);
            }
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        // NICE TO HAVE RERENDER JADI 2 KALI TANPA INI 4 KALI
        if (this.window_location != window.location.search) {
            return true;
        } else if (this.state.articles_found) {
            if (this.state.articles == nextState.articles) {
                return false
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    componentDidMount() {
        this.initializeState();
    }

    componentDidUpdate() {
        // HACK LISTEN QUERY STRING CHANGE
        if (this.window_location != window.location.search) {
            this.setState((state, props) => {
                return this.parseQueryString();
            }, () => {
                this.initializeState();
            });
        }
    }

    initializeState() {
        getAllCategory(categories => {
            categories = categories.reduce((all, cat) => {
                all[cat.id] = cat.name;
                return all;
            }, {});
            this.setState({
                categoriesDict: categories,
                categories_found: true,
            });
        });
        const categoriesId = this.state.selectedOptions;
        const title = this.state.query;
        const offset = this.itemsCountPerPage * (this.state.page - 1);
        const limit = this.itemsCountPerPage;
        this.searchArticle(categoriesId, title, limit, offset, true, (articles, size) => {
            this.setState({
                articles: articles,
                articles_size: size,
                articles_found: true,
            });
        });
    }

    render() {
        var article_content_width = this.state.with_image ? 'col-md-9' : 'col-md-12';

        var search_result = this.state.articles;
        search_result = search_result.map((item, key) => {

            var thumbnail = null
            if (this.state.with_image) {
                var img_thumb = img_blank;
                if (item.img) {
                    img_thumb = item.img;
                }
                thumbnail = (
                    <div className="article-thumb col-3 d-none d-md-block">
                        <img src={img_thumb} />
                    </div>
                )
            }

            return (
                <div className="article-container row mx-md-2 mb-3 mt-3">
                    {thumbnail}
                    <div className={`article-content ${article_content_width} col-12 pl-4`}>
                        <div className="article-title text-left row">
                            {/* <a href={`/article/${item.id}/`}>
                                {item.title}
                            </a> */}
                            <Link to={`/article/${item.slug}/`}>
                                {item.title}
                            </Link>
                        </div>
                        <div className="article-description row">
                            {item.content}
                        </div>
                        <div className="article-type-time row">
                            <div className="col text-left">
                                <p>
                                    <i class="fa fa-calendar mr-2"></i>
                                    {item.date}
                                </p>
                            </div>
                            <div className="col text-right">
                                <Badge
                                    type_id={item.type_id}
                                    type_name={item.type_name}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        });

        // cannot find article with specified criteria
        if (search_result === undefined || search_result.length === 0) {
            search_result = (
                <p className="text-center">
                    Maaf, Kami tidak bisa menemukan artikel yang anda maksud
                </p>
            )
        }

        // loading ...
        if (!this.state.articles_found)
            search_result = (<p className="text-center">Loading ...</p>);

        return (
            <div id="SearchContent-root" className="container-fluid px-md-5 py-3 py-md-5">
                <div id="SearchContent-sizing">
                    <div id="SearchContent-searchbar" className="row mx-md-5 px-sm-3 mt-2 mb-4">
                        <div className="col-12">
                            <SearchBar
                                allOptions={this.state.categoriesDict}
                                selectedOptions={this.state.selectedOptions}
                                unselectedOptions={this.state.unselectedOptions}
                                onSubmit={this.handleFormSubmit}
                            />
                        </div>
                    </div>
                    <div id="SearchContent-article" className="row mx-md-5 px-sm-3">
                        <div className="container-fluid">
                            {search_result}
                        </div>
                    </div>
                    <div id="SearchContent-pagination" className="row mx-md-5 px-sm-3">
                        <div className="col-12 my-4">
                            <Pagination
                                activePage={this.state.page}
                                itemsCountPerPage={this.itemsCountPerPage}
                                totalItemsCount={this.state.articles_size}
                                pageRangeDisplayed={5}
                                onChange={this.handlePagination}
                            />
                        </div>
                    </div>
                </div>
            </div >
        )
    }

}


export default SearchContent;