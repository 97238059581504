import React from "react";
import "./LaporPage.scss";

import NavBar from 'components/NavBar/NavBar';
import Footer from 'components/Footer/Footer';
import { Redirect } from 'react-router';

import Tracker from '../../../../components/Tracker/Tracker';


class LaporPage extends React.Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        document.title = 'Isi Lapor Form | Dinas Pendidikan'
    }

    render() {
        if (!this.props.location.state) {
            return <Redirect to="/lapor/" />
        }

        return (
            <div>
                <Tracker />
                <NavBar />
                <div id="LaporPage-root" className="container-fluid">
                    <div id="lapor-forms" className="">
                        <iframe name="iframe" src={this.props.location.state.iframe_link} scrolling="yes" frameborder="0" sandbox>
                            Loading ...
                        </iframe>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default LaporPage;