import React from "react";
import $ from "jquery";
import "./SearchBar.scss";

/**
 * allOptions = array (id, nama)
 * selectedOptions = yang muncul dan terpilih
 * unselectedOptions = yang muncul tapi gak terpilih
 * selectedOptions + unselectedOptions = muncul di dropdown
 */
class SearchBar extends React.Component {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        // this.state = {
        //     scriptId: "selectFilter",
        //     script: null
        // }
    }

    // componentDidMount() {
    //     const script = document.createElement("script");
    //     script.src = "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.13.1/js/bootstrap-select.min.js";
    //     script.id = this.state.scriptId;
    //     script.async = true;
    //     this.setState({
    //         script: script
    //     });
    // }

    onSubmit(event) {
        // GAK PAKE SELECT FILTER LAGI
        // event.preventDefault();
        // var query, selectedOptions, unselectedOptions;
        // query = $("#searchbar").val();
        // selectedOptions = $("#selectFilter").val();
        // unselectedOptions = $.map($('#selectFilter option'), e => e.value);
        // unselectedOptions = unselectedOptions.filter(opt => !selectedOptions.includes(opt))
        // selectedOptions = selectedOptions.map(opt => parseInt(opt));
        // unselectedOptions = unselectedOptions.map(opt => parseInt(opt));
        // this.props.onSubmit(query, selectedOptions, unselectedOptions);
        event.preventDefault();
        var query = $("#searchbar").val();
        this.props.onSubmit(query, null, []);
    }

    render() {
        // if (this.state.script) {
        //     var insertedDOM = document.querySelectorAll(`script[id='${this.state.scriptId}']`);
        //     if (insertedDOM.length == 0) {
        //         document.body.appendChild(this.state.script);
        //     }
        // } else {
        //     return null;
        // }

        var options = this.props.allOptions;
        var selectedOptions = this.props.selectedOptions;
        var unselectedOptions = this.props.unselectedOptions;

        options = Object.keys(options).map(function (categoryId, index) {
            categoryId = parseInt(categoryId);
            const categoryName = options[categoryId];
            if (selectedOptions && selectedOptions.includes(categoryId)) {
                return (
                    <option value={categoryId} selected>{categoryName}</option>
                );
            } else if (unselectedOptions && unselectedOptions.includes(categoryId)) {
                return (
                    <option value={categoryId}>{categoryName}</option>
                );
            } else {
                return null;
            }
        });

        options = options.filter(option => option != null);

        return (
            <div id="SearchBar-root" className="container-fluid">
                <form className="form-inline" onSubmit={this.onSubmit} accept-charset="UTF-8">
                    <div className="input-group flex-fill">
                        <div class="input-group-prepend">
                            {/* https://developer.snapappointments.com/bootstrap-select/ */}
                            {/* <select id="selectFilter" className="selectpicker" multiple
                                data-selected-text-format="count > 1"
                                data-live-search="true"
                            >
                                {options}
                            </select> */}
                        </div>
                        <input id="searchbar" className="form-control mr-2 ml-2"
                            type="search" name="search"
                            placeholder="Cari Artikel ..."
                            aria-label="Search this site" />
                        <div class="input-group-append">
                            <input className="btn btn-outline-secondary"
                                type="submit" name="commit" value="Search"
                                data-disable-with="Search" />
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}


export default SearchBar;