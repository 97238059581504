import React from "react";
import Imageshow from "./subcomponents/Imageshow";
import "./Masthead.scss";

import { getCarousel } from "../../../../services/View";


class Masthead extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            carousel: []
        };
    }

    componentDidMount() {
        getCarousel(links => {
            this.setState({
                carousel: JSON.parse(links)
            });
        });
    }

    render() {
        return (
            <div>
                <div className="container-fluid masthead">

                    <div id="carouselMastheadIndicators" className="carouselMast carousel slide" data-ride="carousel">
                        <ol className="carousel-indicators">
                            {
                                this.state.carousel.map((link, idx) => {
                                    return (
                                        <li data-target="#carouselMastheadIndicators" data-slide-to={`${idx}`} className={`${idx == 0 ? 'active' : ''}`}></li>
                                    )
                                })
                            }
                        </ol>
                        <div className="carousel-inner" role="listbox">
                            {
                                this.state.carousel.map((link, idx) => {
                                    return (
                                        <div className={`carousel-item ${idx == 0 ? 'active' : ''}`} style={{ "backgroundImage": `url('${link.link}')` }}>
                                            <div className="carousel-caption d-none d-md-block">
                                                <h2 className="display-4">
                                                    {link.title}
                                                </h2>
                                                <p className="lead">
                                                    <a href={link.href} target="_blank" >
                                                        <span className="abu">{link.description}</span>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <a className="carousel-control-prev" href="#carouselMastheadIndicators" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselMastheadIndicators" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>

                </div>
            </div>
        );
    }
}


export default Masthead;