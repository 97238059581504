import ApiClient from '../utils/ApiRequest';


function getGallery(categoriesId, title, limit, offset, callback) {
  __getGalleryHelper(categoriesId, title, null, limit, offset, callback);
}

function getGalleryYoutube(categoriesId, title, limit, offset, callback) {
  __getGalleryHelper(categoriesId, title, "YOUTUBE_LINK", limit, offset, callback);
}

function __getGalleryHelper(categoriesId, title, contentType, limit, offset, callback) {
  // parameter
  var catidFind = categoriesId && categoriesId.length > 0 ? `categoriesId: [${categoriesId.map(id => id.toString()).join()}]` : "";
  var titleFind = title ? `title: $title` : "";
  var limit = limit ? limit : 5;
  var offset = offset ? offset : 0;
  // variable
  var variable = {
    title: title
  }
  var variable_query = title ? "($title: String)" : "";
  // query
  ApiClient.request(`
  query gallery ${variable_query} {
      getGalleries
      (
        find: {
          ${catidFind}
          ${titleFind}
          ${contentType ? `contentType: ${contentType}` : ""}
          reviewStatus: ACCEPT
        }
        limit: ${limit.toString()}
        offset: ${offset.toString()}
      )
      {
          id
          category {
            id
            name
          }
          title
          mediaLink
          description
          publishedAt
          publishedBy {
            name
          }
          hits
          contentType {
            id
            name
          }
      }
  }
  `, variable).then(gal => {
    const gallery = gal.getGalleries;
    if (gallery) {
      callback(gallery);
    }
  });
}




function getSize(categoriesId, title, callback) {
  // parameter
  var catidFind = categoriesId && categoriesId.length > 0 ? `categoriesId: [${categoriesId.map(id => id.toString()).join()}]` : "";
  var titleFind = title ? `title: $title` : "";
  // variable
  var variable = {
    title: title
  }
  var variable_query = title ? "($title: String)" : "";
  // query
  ApiClient.request(`
    query gallery ${variable_query} {
        getGalleries
        (
          find: {
            ${catidFind}
            ${titleFind}
            reviewStatus: ACCEPT
          }
        )
        {
          id
        }
    }
    `, variable).then(gal => {
    const galleries = gal.getGalleries;
    if (galleries) {
      callback(galleries.length);
    }
  });
}


export { getGallery, getGalleryYoutube, getSize }