import React from "react";
import "./Lapor.scss";

import NavBar from 'components/NavBar/NavBar';
import Footer from 'components/Footer/Footer';
import LaporCard from './components/LaporCard/LaporCard';

import img_banjir from 'assets/images/lapor/banjir.jpeg';
import img_prestasi from 'assets/images/lapor/prestasi.png';
import img_bully from 'assets/images/lapor/bully.jpeg';
import img_internet from 'assets/images/lapor/internet.jpeg';
import img_pengaduan from 'assets/images/lapor/pengaduan.jpeg';

import Chat from "components/Chat/Chat";
import Tracker from '../../components/Tracker/Tracker';


class Lapor extends React.Component {

    componentWillMount() {
        document.title = 'Lapor | Dinas Pendidikan'
    }

    render() {
        return (
            <div>
                <Tracker />
                <Chat />
                <NavBar />
                <div id="Lapor-root" className="container-fluid px-5 py-5">
                    <div className="row px-md-5 px-3 py-3 justify-content-center">
                        <LaporCard
                            title="Lapor Banjir Sekolah"
                            description={`
                                Berikut ini adalah formulir untuk mengumpulkan data laporan banjir yang dialami 
                                sekolah negeri dan swasta di DKI Jakarta.
                            `}
                            link="https://forms.gle/qx5sxDNwdS5PpELw5"
                            img={img_banjir}
                        />
                        {/* <LaporCard
                            title="Pengaduan"
                            description={`
                                Formulir Laporan dari Masyarakat dan Komunitas Pendidikan
                            `}
                            link="https://docs.google.com/forms/d/e/1FAIpQLSdFUOg6SgZx7OHMrJxGnCpYXoT3j-sQec6PkWtPvZmX3l4j8A/viewform"
                            img={img_pengaduan}
                        />
                        <LaporCard
                            title="Prestasi/Kegiatan Sekolah"
                            description={`
                                Prestasi/Kegiatan Sekolah Akan diseleksi untuk di muat pada Berita Disdik
                            `}
                            link="https://docs.google.com/forms/d/e/1FAIpQLSfSKhXC4TsE6x9L4zpvFyA-cUH_hLSvKpS89Ay9pIu7gLJIEA/viewform"
                            img={img_prestasi}
                        />
                        <LaporCard
                            title="Lapor Perundungan"
                            description={`
                                Lapor Perundungan / Bullying yang terjadi di lingkungan sekolah
                            `}
                            link="https://docs.google.com/forms/d/e/1FAIpQLSckcVKDjWX6Ryo31Soc8qpb5BWUQA2BZk1VKckvFGLxuzuVHw/viewform"
                            img={img_bully}
                        />
                        <LaporCard
                            title="Pendataan Penggunaan Internet Sekolah"
                            description={`
                                Instrumen untuk mengetahui jenis ISP dan kapasitas bandwith setiap ISP 
                                yang digunakan oleh sekolah
                            `}
                            link="https://docs.google.com/forms/d/e/1FAIpQLSd1W1xTGAHJ7DDb-293mQ8a_MJJJLwVmCsi_Jd1Z--zy9KTyQ/viewform?usp=sf_link"
                            img={img_internet}
                        /> */}
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}


export default Lapor;