import React from "react";
import "./ArticleBox.scss";
import Badge from "components/Badges/Badge"
import { VideoLink } from "components/VideoPopup/VideoPopup";


function ArticleBox(props) {
    return (
        <div className="galleryBox">
            <div className="card">
                <img className="card-img-top" src={props.img} alt="Card image cap" />
                <div className="card-body body-title">
                    <p className="card-text mb-2">
                        {props.date}
                    </p>
                    <div className="badge-img">
                        <Badge type_id={props.type_id} type_name={props.type_name} />
                    </div>
                    {props.isVideo ? (
                        <VideoLink>
                            <h5 className="card-title mb-2">{props.title}</h5>
                        </VideoLink>
                    ) : (
                            <h5 className="card-title mb-2">
                                <a href={props.mediaLink}>
                                    {props.title}
                                </a>
                            </h5>
                        )}
                    {props.children}
                </div>
            </div>
            {/* <div id="box" className="container justify-content-center">
                <div className="row justify-content-center">
                    <img className="imgThumb" src={props.img} />
                </div>
                <div className="row title text-left">
                    {props.isVideo ? (
                        <VideoLink>
                            {props.title}
                        </VideoLink>
                    ) : (
                            <a href={props.mediaLink}>{props.title}</a>
                        )}
                </div>
                <div className="row">
                    {props.children}
                </div>
                <div className="row misc">
                    <div className="col text-left">
                        <p>
                            <i class="fa fa-calendar mr-2"></i>
                            {props.date}
                        </p>
                    </div>
                    <div className="col text-right">
                        <Badge type_id={props.type_id} />
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default ArticleBox;