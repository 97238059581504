import React from "react";
import { withRouter, BrowserRouter as Router, Route } from "react-router-dom";
import Home from "pages/Home/Home";
import Profile from "pages/Profile/Profile";
import ArticleSearch from "pages/ArticleSearch/ArticleSearch";
import ArticlePage from "pages/ArticlePage/ArticlePage";
import Gallery from "pages/Gallery/Gallery";
import Lapor from "pages/Lapor/Lapor";
import LaporPage from "pages/Lapor/components/LaporPage/LaporPage";


class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }
  render() {
    return this.props.children
  }
}


function AppRouter() {
  var Scroll = withRouter(ScrollToTop);
  return (
    <Router>
      <Scroll>
        <Route path="/" exact component={Home} />
        <Route path="/profile/" component={Profile} />
        <Route path="/article/" component={ArticleSearch} exact />
        <Route path="/article/:slug/" component={ArticlePage} />
        <Route path="/gallery/" component={Gallery} />
        <Route path="/lapor/" component={Lapor} exact />
        <Route path="/lapor/form/" component={LaporPage} />
      </Scroll>
    </Router>
  );
}



export default AppRouter;