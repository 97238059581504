import React from "react";
import "./ArticleNewsDisplay.scss";

import HomeContent from "components/Layout/HomeContent/HomeContent";
import NewsBox from "./subcomponents/NewsBox/NewsBox";
import { getNewestArticle } from '../../../../services/Article';
import { formattedDate } from '../../../../utils/Date';
import { getNewsBox } from '../../../../services/View';


class ArticleNewsDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            articleGroups: [],
            found: false,
        }
    }

    componentDidMount() {
        getNewsBox(news_str => {
            var news = JSON.parse(news_str);
            var allArticle = news.map(item => getNewestArticle(item.categoriesId));
            Promise.all(allArticle).then(data => {
                var articleGroups = [];
                for (var id = 0; id < news.length; id++) {
                    articleGroups.push({
                        id: id,
                        name: news[id].name,
                        withImage: news[id].withImage,
                        articles: data[id].map(item => {
                            return {
                                id: item.id,
                                img: item.thumbnail,
                                slug: item.slug,
                                title: item.title,
                                date: formattedDate(item.publishedAt, 'd M y'),
                                content: item.description,
                                type_id: item.category.id,
                                type_name: item.category.name
                            }
                        })
                    });
                }
                this.setState({
                    articleGroups: articleGroups,
                    found: true,
                })
            });
        });
    }

    render() {
        if (!this.state.found)
            return (<p className="text-center">Loading ...</p>);

        return (
            <div id="ArticleNewsDisplay-root">
                <HomeContent title="Artikel Terbaru" hide_title={true}>
                    <div id="tabs" className="container-fluid py-1">
                        <nav>
                            <div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                                {
                                    this.state.articleGroups.map((news, idx) => {
                                        var className = idx == 0 ? "active" : "";
                                        return (
                                            <a className={`nav-item nav-link ${className} px-md-3 news-tab`} id={`nav-${news.id}-tab`} data-toggle="tab" href={`#nav-${news.id}`} role="tab">
                                                <div className="container-fluid">
                                                    <div className="row text-center">
                                                        <div className="col tab-text">
                                                            {news.name}
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        )
                                    })
                                }
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            {
                                this.state.articleGroups.map((news, idx) => {
                                    var className = idx == 0 ? "show active" : "";
                                    return (
                                        <div className={`tab-pane fade ${className}`} id={`nav-${news.id}`} role="tabpanel">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <NewsBox articles={news.articles} withImage={news.withImage} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </HomeContent>
            </div>
        );
    }

}


export default ArticleNewsDisplay;